<template>
  <div id="roleSetUp">
    <PageLoading :loading="loading" />
    <div class="addedit-title" v-if="otherId==0">{{Title1}}</div>
    <div class="addedit-title" v-else>{{Title0}}</div>
    <div class="addedit-bor"></div>
    <n-form 
        :model="formValue"
        label-placement="left"
      >
      
        <n-form-item
          label="角色名称 : "
          path="formValue.name"
          :label-width="120"
          style="width: 320px"
        >
          <n-input
            maxlength="50"
          show-count
          clearable
          v-model:value="formValue.name"
          />
        </n-form-item>

        <n-form-item
          label="备注 : "
          path="formValue.remark"
          :label-width="120"
          style="width: 320px"
        >
          <n-input
          v-model:value="formValue.remark"
          />
        </n-form-item>
        
        <n-form-item
          label="权限设置 : "
          path="data"
          :label-width="120"
        >
          <n-tree
          :block-line="true"
          :block-node="true"
          :default-expand-all="false"
          :cascade="true"
          :checkable="true"
          :data="data"
          :isLeaf="true"
          :default-expanded-keys="allID"
          :default-checked-keys="Select"
          @update:checked-keys="updateCheckedKeys"
          />
                
        </n-form-item>
          <n-form-item
          label="公共权限设置 : "
          path="Pdata"
          :label-width="120"
        >
          <n-tree
          :block-line="true"
          :block-node="true"
          :default-expand-all="false"
          :cascade="true"
          :checkable="true"
          :data="Pdata"
          :isLeaf="true"
          :disabled="true"
          :default-expanded-keys="allID"
          :default-checked-keys="Pselect"
          @update:checked-keys="publicCpdateCheckedKeys"
          />
                
        </n-form-item>

        <NFormItem>
          <div id="role-btns">
            <NButton @click="ReturnToList">返回</NButton>
            <NButton type="primary" @click="SubmitEvent">确定</NButton>
          </div>
        </NFormItem>
    </n-form>
    
  </div>
</template>

<script>
import { reactive, ref, h, defineComponent } from "vue";
import { NButton, useMessage } from "naive-ui";
import { resStatusEnum } from "@/enumerators/http.js";
import {
  GETSystemPowerAlMenu,
  GETSystemPowerRoleDetail,
  PostSystemPowerRolesCreate,
  PostSystemPowerRolesUpdate,
} from "@/api/admin-role.js"; // 新增时列表 // 编辑时列表 新增提交 编辑提交
import { toRaw } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import RoleList from "@/enumerators/role-list.js";
import PageLoading from "@/components/PageLoading/index.vue";

const { SUCCESS } = resStatusEnum;
const loading = ref(true);
loading.value = true

export default defineComponent({
  // 挂载组件
  components:{
    PageLoading
  },
  setup() {
    const message = useMessage();
    const route = useRoute();
    const router = useRouter();
    let otherId = route.query.id;
    const Select = ref(route.query.select);
    const Pselect = ref(route.query.pselect);
    const data = ref(toRaw([]));
    const Pdata = ref(toRaw([]));
    const formValue = ref({
      name: "",
      remark: null
    })
    const allID = ref([]);

    // 格式化 树组件样式
    function LoadComplete() {
      setTimeout(function () {
        let Ntre = document.getElementsByClassName("n-tree-node-wrapper");
        for (var j = 0; j < Ntre.length; j++) {
          var NtreChiden = Ntre[j].getElementsByClassName("n-tree-node--selectable")[0].children;
          for (var i = 0; i < NtreChiden.length; i++) {
            let str = NtreChiden[i].getAttribute("class");
            if (str.indexOf("n-tree-node-switcher--expanded") > 1 && str.indexOf("n-tree-node-switcher--hide") < 1 ) {
              Ntre[j].classList.add("divblock");
            }
          }
        }
      }, 1000);
    }

    // 编辑时初始化 权限设置
    function editInit() {
      loading.value = true;
      let param = { "AdminRole[id]": otherId };
      let TemporaryData = GETSystemPowerRoleDetail(param)
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            // message.info(res.msg);
            formValue.value.name = res.data.name
            formValue.value.remark = res.data.remark
            LoadComplete();
            return res.data.all_munes;
          }
        })
        .catch((reject) => {});
        TemporaryData.then(req=>{
          data.value = req
        })
        return data
    }
   
    // 编辑时初始化 公共权限设置
    function PeditInit() {
      loading.value = true;
      let param = { "AdminRole[id]": otherId };
      let TemporaryData = GETSystemPowerRoleDetail(param)
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            // message.info(res.msg);
            LoadComplete();
            return res.data.system_menus;
          }
        })
        .catch((reject) => {});
        TemporaryData.then(req=>{
          Pdata.value = req
        })
        return Pdata
    }

    // 页面跳转
    function JumpMethod(params) {
      router.push({
        path:'/system-power/roles/list',
      })
    }
    
    // 新增时初始化 权限设置
    function addInit(param) {
      loading.value = true;
      let TemporaryData = GETSystemPowerAlMenu()
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            // message.info(res.msg);
            LoadComplete();
            return res.data.menus;
          }
        })
        .catch((reject) => {});
        TemporaryData.then(req=>{
          data.value = req
        })
        return data
    }

    // 新增时初始化 公共权限设置
    function PaddInit(param) {
      loading.value = true;
      let TemporaryData = GETSystemPowerAlMenu()
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            // message.info(res.msg);
            LoadComplete();
            return res.data.system_menus;
          }
        })
        .catch((reject) => {});
        TemporaryData.then(req=>{ 
          Pdata.value = req
        })
        return Pdata
    }

    // 提交事件
    function SubmitEvent(params) {
      loading.value = true;
      if(otherId){
        // 编辑提交
        let params = {
          "AdminRole[name]":formValue.value.name,
          "AdminRole[remark]":formValue.value.remark,
          "menu_id":Select.value,
          "public_menu_id":Pselect.value,
          "AdminRole[id]":otherId
        }
        // console.log('提交事件',Pselect.value);
        PostSystemPowerRolesUpdate(params).then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            // message.info(res.msg);
            setTimeout(function(){
              window.location.href = '/'
            },1500)
          }
        })
        .catch((reject) => {});
      }else{
        // 新增提交

        let params = {
          "AdminRole[name]":formValue.value.name,
          "AdminRole[remark]":formValue.value.remark,
          "menu_id":Select.value,
          "public_menu_id":Pselect.value
        }
        PostSystemPowerRolesCreate(params).then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            setTimeout(function(){
              JumpMethod()
            },1500)
          }else{
            loading.value = false;
            // message.info(res.msg);
          }
        })
        .catch((reject) => {
        });
      }
    }
    return {
      // 权限设置
      data: otherId ? editInit() : addInit(),
      formValue,
      allID:RoleList,
      Select,
      Title0:"编辑角色",
      Title1:"新增角色",
      otherId,
      loading,
      SubmitEvent,
      updateCheckedKeys: (v) => {
        console.log('权限设置',v);
        Select.value = v
      },
      // 公共权限设置
      Pdata: otherId ? PeditInit() : PaddInit(),
      Pselect,
      publicCpdateCheckedKeys: (v) => {
        Pselect.value = v
      },
      // 页面跳转
      ReturnToList() {
        router.push({
          path:'/system-power/roles/list',
        })
      }
    };
    
  },
});
</script>

<style>
#roleSetUp {
  background: #fff;
}
#roleSetUp .addedit-title {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 78px;
  text-indent: 20px;
}
.addedit-bor {
  height: 1px;
  border-bottom: 1px solid #d7d7d7;
  position: absolute;
  left: 0;
  right: 0;
  top: 65px;
}
/* #roleSetUp .n-tree .n-tree-node-wrapper{
  display: inline-block;
} */
#roleSetUp .n-tree .n-tree-node-wrapper{
  display: inline-block;
}
#roleSetUp
  .n-tree
  .n-tree-node-wrapper:not(:nth-child(1)):not(:nth-child(2))
  .n-tree-node-indent {
  display: none !important;
}
.n-tree-node-switcher.n-tree-node-switcher--expanded{
  pointer-events:none
}
#role-btns{
  padding-left: 120px;
  margin-top: 50px;
}
#role-btns .n-button{
  margin-right: 50px;
  width:120px;
}
.divblock {
  display: block !important;
}

</style>